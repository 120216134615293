import { Text } from "@clipboard-health/ui-react";
// TODO: Use our own Chip
// eslint-disable-next-line no-restricted-imports
import { Chip, Stack } from "@mui/material";
import { CbhIcon, type IconType } from "@src/appV2/redesign/components/CbhIcon";
import { type Control, Controller, type FieldPath, type FieldValues } from "react-hook-form";

interface FilterOption {
  label: string;
  value: string;
  icon?: IconType;
}

interface FilterChipsProps<TFormValues extends FieldValues> {
  name: FieldPath<TFormValues>;
  control: Control<TFormValues>;
  options: FilterOption[];
  title: string;
  allOptions?: boolean;
}

export function FilterChips<TFormValues extends FieldValues>(props: FilterChipsProps<TFormValues>) {
  const { name, control, options, title, allOptions = true } = props;

  return (
    <Stack spacing={7}>
      <Text semibold variant="body1">
        {title}
      </Text>
      <Stack direction="row" gap={3} flexWrap="wrap">
        <Controller
          name={name}
          control={control}
          // eslint-disable-next-line sonarjs/cognitive-complexity
          render={({ field }) => {
            const values = field.value as string | undefined;
            const selectedValues = values?.split(",").filter(Boolean) ?? [];
            const allValues = options.map((option) => option.value);
            const isAllSelected =
              selectedValues.length === allValues.length &&
              selectedValues.every((value) => allValues.includes(value));

            function handleChipClick(value: string) {
              if (value === "All") {
                field.onChange(isAllSelected ? "" : allValues.join(","));
                return;
              }

              const isSelected = selectedValues.includes(value);
              const newValues = isSelected
                ? selectedValues.filter((v) => v !== value)
                : [...selectedValues, value];
              field.onChange(newValues.join(","));
            }

            return (
              <>
                {allOptions && (
                  <Chip
                    key="All"
                    label="All"
                    variant="outlined"
                    color={isAllSelected ? "primary" : "default"}
                    sx={{
                      "&.MuiChip-root": {
                        border: isAllSelected ? "1px solid" : "0.5px solid",
                        borderColor: isAllSelected
                          ? (theme) => theme.palette.primary.main
                          : (theme) => theme.palette.border?.primary,
                        backgroundColor: (theme) => theme.palette.background?.tertiary,
                        "&:hover": {
                          backgroundColor: (theme) => theme.palette.background?.tertiary,
                        },
                      },
                    }}
                    onClick={() => {
                      handleChipClick("All");
                    }}
                  />
                )}
                {options.map((option) => {
                  const isSelected = selectedValues.includes(option.value);
                  return (
                    <Chip
                      key={option.value}
                      label={option.label}
                      icon={option.icon ? <CbhIcon type={option.icon} size="small" /> : undefined}
                      variant="outlined"
                      color={isSelected ? "primary" : "default"}
                      sx={{
                        "&.MuiChip-root": {
                          border: isSelected ? "1px solid" : "0.5px solid",
                          borderColor: isSelected
                            ? (theme) => theme.palette.primary.main
                            : (theme) => theme.palette.border?.primary,
                          backgroundColor: (theme) => theme.palette.background?.tertiary,
                          "&:hover": {
                            backgroundColor: (theme) => theme.palette.background?.tertiary,
                          },
                        },
                      }}
                      onClick={() => {
                        handleChipClick(option.value);
                      }}
                    />
                  );
                })}
              </>
            );
          }}
        />
      </Stack>
    </Stack>
  );
}
