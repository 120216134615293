// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/prefer-spread */
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";

import { BottomSheet } from "../../components/BottomSheet";
import { DialogFooter } from "../../components/DialogFooter";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { Pill } from "../../components/Pill";
import { jobTypeOptions } from "../../PlacementCandidate/constants";
import { type ShiftNameType } from "../../PlacementCandidate/types";
import { usePayTransparencyFlag } from "../lib/usePayTransparencyFlag";
import { useTransparencyTableData } from "../lib/useTransparencyTableData";
import { type PlacementData } from "../types/fetchPlacements.schema";
import { type ExternalPayRate, type PayRate } from "../types/payRate";
import { formatPayRange } from "../utils";
import { EstimatedPayTable } from "./EstimatedPayTable";

interface EstimatedPayBottomSheetProps {
  modalState: UseModalState;
  placement: PlacementData | undefined;
  payRate: ExternalPayRate | undefined;
}

function tableDataToEventData(
  tableData: Record<string, Array<{ slot: ShiftNameType; rate: PayRate | undefined }>>
) {
  return Object.entries(tableData).map(([jobType, rates]) => ({
    jobType,
    rates: rates.map(({ slot, rate }) => ({ slot, rate: rate?.exact })),
  }));
}

export function EstimatedPayBottomSheet(props: EstimatedPayBottomSheetProps) {
  const { modalState, placement, payRate } = props;

  const payRateExperiment = usePayTransparencyFlag();

  const [firstMatchingQualification] = payRate?.externallyDerived
    ? Array.from(payRate.matchedQualifications.values())
    : [];
  const [firstMatchingTimeSlot] = payRate?.externallyDerived
    ? Array.from(payRate.matchedTimeSlots.values())
    : [];
  const [firstMatchingJobType] = payRate?.externallyDerived
    ? Array.from(payRate.matchedJobTypes.values())
    : [];

  const jobTypeLabel = jobTypeOptions
    .find((option) => option.value === firstMatchingJobType)
    ?.label.toLowerCase();

  const { tableData } = useTransparencyTableData(firstMatchingQualification, placement);

  useLogEffect(
    APP_V2_APP_EVENTS.PLACEMENT_PAY_BREAKDOWN_VIEWED,
    {
      placementId: placement?.id,
      payRate: payRate?.exact,
      tableData: tableDataToEventData(tableData),
    },
    { enabled: modalState.modalIsOpen }
  );

  function logEstimateDescriptionClicked() {
    logEvent(APP_V2_APP_EVENTS.PLACEMENT_PAY_BREAKDOWN_DESCRIPTION_CLICKED, {
      placementId: placement?.id,
      qualification: firstMatchingQualification,
    });
  }

  return payRateExperiment.enabled ? (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        />
      }
    >
      <DialogContent sx={{ paddingBottom: 12 }}>
        <Stack spacing={8}>
          <PageHeaderWrapper variant="tertiary">
            <Stack direction="column" alignItems="center" spacing={4}>
              <Pill
                size="medium"
                label={payRateExperiment.labelText}
                color={(theme) => theme.palette.intent?.success.text}
                backgroundColor={(theme) => theme.palette.intent?.success.border}
              />
              {payRate && (
                <Title variant="h2" component="h1">
                  {formatPayRange(payRate, false)}/hr
                </Title>
              )}
            </Stack>
          </PageHeaderWrapper>
          <Stack direction="column" spacing={6}>
            <Text
              variant="body1"
              color={(theme) => theme.palette.text.secondary}
              sx={{ textAlign: "center" }}
              data-testid="estimated-pay-description"
              onClick={() => {
                logEstimateDescriptionClicked();
              }}
            >
              This estimate is for a <strong>{firstMatchingQualification}</strong> with your
              experience working <strong>{firstMatchingTimeSlot?.toUpperCase()}</strong> shifts{" "}
              <strong>{jobTypeLabel}</strong>.
            </Text>
            <Text
              variant="body1"
              color={(theme) => theme.palette.text.secondary}
              sx={{ textAlign: "center" }}
            >
              Our estimates are based on employer and community-provided information.
            </Text>
          </Stack>

          <EstimatedPayTable tableData={tableData} data-testid="estimated-pay-table" />
        </Stack>
      </DialogContent>
    </BottomSheet>
  ) : null;
}
