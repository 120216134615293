import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { BottomSheet } from "../../components/BottomSheet";
import { DialogFooter } from "../../components/DialogFooter";
import { Illustration } from "../../components/Illustration";
import { TextButton } from "../../components/TextButton";
import { invalidatePayTransparencyData } from "../api/useGetPayTransparencyData";
import { useInitiateSurveyCall } from "../api/useInitiateSurveyCall";
import { usePayTransparencyFlag } from "../lib/usePayTransparencyFlag";

export interface AutomatedCallBottomSheetProps {
  modalState: UseModalState;
}

export function AutomatedCallBottomSheet(props: AutomatedCallBottomSheetProps) {
  const { modalState } = props;

  const { mutate: initiateSurveyCall } = useInitiateSurveyCall();
  const payTransparency = usePayTransparencyFlag();
  const queryClient = useQueryClient();
  const [errorWithCall, setErrorWithCall] = useState(false);

  useLogEffect(
    APP_V2_APP_EVENTS.PLACEMENT_PAY_TRANSPARENCY_CALL_MODAL_OPENED,
    {},
    { enabled: modalState.modalIsOpen }
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (modalState.modalIsOpen && payTransparency.enabled) {
      timeoutId = setTimeout(() => {
        initiateSurveyCall({});
      }, payTransparency.callModal.delayBeforeInitiatingCall);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [initiateSurveyCall, modalState.modalIsOpen, payTransparency]);

  function handleCallNotReceived() {
    logEvent(APP_V2_APP_EVENTS.PLACEMENT_PAY_TRANSPARENCY_CALL_ISSUES, {});
    setErrorWithCall(true);
  }

  if (!payTransparency.enabled) {
    return null;
  }

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
            setErrorWithCall(false);
            invalidatePayTransparencyData(queryClient);
          }}
        />
      }
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <Stack direction="column" spacing={8} sx={{ alignItems: "center" }}>
          <Illustration type="phone" />
          <Text variant="h2">{payTransparency.callModal.title}</Text>
          <Text variant="body1" sx={{ textWrap: "balance" }}>
            {payTransparency.callModal.subtext}
          </Text>
          {!errorWithCall && (
            <TextButton
              size="large"
              onClick={() => {
                handleCallNotReceived();
              }}
            >
              I have not received a call
            </TextButton>
          )}
          {errorWithCall && (
            <Text bold variant="body1" sx={{ textWrap: "balance" }}>
              {payTransparency.callModal.errorResponse}
            </Text>
          )}
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
