import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import { JobType, type ShiftNameType } from "../../PlacementCandidate/types";
import { type PayRate } from "../types/payRate";
import { formatPayRange } from "../utils";

interface EstimatedPayTableProps {
  tableData: Record<JobType, Array<{ slot: ShiftNameType; rate: PayRate | undefined }>>;
}

export function EstimatedPayTable({ tableData }: EstimatedPayTableProps) {
  return (
    <TableContainer>
      <Table
        data-testid="estimated-pay-table"
        sx={(theme) => ({
          "& tbody > tr:not(:last-child)": { borderBottom: `2px solid ${theme.palette.divider}` },
          "& tr > td": { padding: 4 },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>AM</TableCell>
            <TableCell>PM</TableCell>
            <TableCell>NOC</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Full-time</TableCell>
            {tableData[JobType.FULL_TIME].map(({ slot, rate }) => (
              <TableCell key={`${slot}-full-time`}>
                {rate ? formatPayRange(rate, false) : "-"}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Part-time</TableCell>
            {tableData[JobType.PART_TIME].map(({ slot, rate }) => (
              <TableCell key={`${slot}-part-time`}>
                {rate ? formatPayRange(rate, false) : "-"}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>PRN</TableCell>
            {tableData[JobType.PER_DIEM].map(({ slot, rate }) => (
              <TableCell key={`${slot}-prn`}>{rate ? formatPayRange(rate, false) : "-"}</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Weekends</TableCell>
            {tableData[JobType.WEEKEND_WARRIOR].map(({ slot, rate }) => (
              <TableCell key={`${slot}-weekends`}>
                {rate ? formatPayRange(rate, false) : "-"}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
